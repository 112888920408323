html {
  scroll-behavior: smooth;
}

body {
  background: #000;

  min-height: 100vh;
  font-family: "Open Sans", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
* {
  -webkit-font-smoothing: antialiased;
}
* {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
*::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

* {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.color {
  /* color: #8453ed; */
  font-weight: bold;
}
.color2 {
  color: aliceblue;
}

.container {
  /* */
  margin: 0 120px;

  display: flex; /* Add flex display */
  justify-content: space-between; /* Ensure space between header items */
  align-items: center;
  flex-wrap: wrap;
}
header .container {
  max-width: 1200px;
  width: 90%;
}
header {
  background: none;
  color: #fff;
  padding: 1em 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
  /* Make the header sticky */
  top: 0;
}

header h1 {
  margin: 0;
  font-size: 1.6em;
  font-weight: 500;
  color: #fff;
}
header h1 a {
  color: #d2d2d2;
  text-decoration: none;
}
header h1 img {
  padding-right: 3px;
  position: relative;
  top: 4px;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

nav a {
  color: #fff;
  text-decoration: none;
}

nav a:hover {
  color: #8453ed;
}
.contact-btn {
  display: flex;
  padding: 0.675em 1.5em;
  border-radius: 5px;
  background: linear-gradient(90deg, #3f51b5, transparent) #2196f3;
  font-family: inherit;
  color: #fff;
  box-shadow: 0px 0px 150px #000000;
  z-index: 2;

  text-decoration: none;
  transition: background-color 1s;
  place-content: center;
}
.contact-btn:hover {
  background-color: rgba(132, 83, 237, 1);
  color: #fff;
  transition: all 200ms ease-in;
  transform: scale(1.1);
}
/* .floatbcont {
  width: 100%;
  height: 70px;
  position: fixed;
  bottom: 0;
  background-color: #000;
  z-index: 1000;
} */
.apply-now-button,
.apply-now-button2 {
  position: fixed;

  background: linear-gradient(90deg, #3f51b5, transparent) #5356ec;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 1000; /* Ensure it appears above other content */
}
.apply-now-button {
  bottom: 20px;
  left: 20px;
}
.apply-now-button2 {
  bottom: 20px;
  right: 20px;
}
.hero {
  background: none;
  /* height: 200px; */
  color: #fff;
  text-align: center;
  padding: 4em 0;
  padding-left: 10.2em;
  padding-right: 6em;
}

.hero h2 {
  font-size: 2em;
  text-align: start;
  margin-left: 5px;
}

.hero p {
  margin: 0.5em 0 1.5em;
  font-size: 1.2em;
  text-align: start;
}

.btn-primary {
  padding: 0.675em 1.5em;
  border-radius: 5px;
  background: linear-gradient(90deg, #3f51b5, transparent) #5356ec;
  font-family: inherit;
  color: #fff;
  text-decoration: none;
  transition: background-color 1s;
  place-content: center;
}
.btn-primary:hover {
  background-color: rgba(132, 83, 237, 1);
  color: #fff;
  transition: all 200ms ease-in;
  transform: scale(1.1);
}

.services {
  background: none;
  padding: 4em 0;
  text-align: center;
}

.services .con {
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.services .service-item {
  background: linear-gradient(to top left, #000 80%, rgba(132, 83, 237, 0.7));
  padding: 1em;
  border: 1px solid rgba(255, 255, 255, 0.41);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(25% - 1em);
  margin: 0.5em;
  box-sizing: border-box;
}
.tools-block {
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto auto auto;
  grid-auto-columns: 1fr;
  max-width: 480px;
  margin: 60px auto;
  display: grid;
}
.tools {
  color: #fff;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  text-align: center;
}
.tools-img {
  /* background: linear-gradient(to top left, #000 70%, rgba(132, 83, 237, 0.6)); */
  border: 1px solid rgba(255, 255, 255, 0.41);
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 85px;
  margin-bottom: 12px;

  display: flex;
}
.tools-wrap {
  grid-row-gap: 20px;
  flex-direction: column;
}
img {
  max-width: 100%;
}
.about {
  color: #fff;
  background: none;
  padding: 2em 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about .stats .stat h4 {
  /* color: #8453ed; */
  font-weight: bold;
}
.about .stats {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
  align-items: center;
  margin-top: 2em;
}

.about .stat {
  background: linear-gradient(to top left, #000 80%, rgba(132, 83, 237, 0.7));
  padding: 1em;
  max-width: 300px;
  max-height: 157px;
  border: 1px solid rgba(255, 255, 255, 0.41);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(25% - 1em);
  margin: 0.5em;
  box-sizing: border-box;
}

.process {
  color: #fff;
  background: none;
  padding: 1em 4em;
  text-align: center;
}

.process ol {
  list-style: none;
  padding: 25px;

  margin: 0;
  text-align: center;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1em;
}

.process .container .testimonials {
  background: #f0f0f0;
  padding: 4em 0;
  text-align: center;
}
.process .container {
  display: flex;

  flex-direction: column;
}
.process .container h2 {
  margin: 0;
}
.testimonials {
  color: #fff;
  margin-top: 1em;
}
.testimonials blockquote {
  background: linear-gradient(to top left, #000 80%, rgba(132, 83, 237, 0.7));
  max-width: 275px;
  max-height: 150px;
  padding: 2em;
  border: 1px solid rgba(255, 255, 255, 0.41);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.testimonials blockquote cite {
  padding-bottom: 10px;
}
.testimonials .container {
  display: flex;
  flex-direction: row;
}
.testimonials .container h2 {
  width: 100%;
  text-align: center;
}
.testimonials .container p {
  max-height: 108px;
}
.faq {
  color: #fff;
  background: none;
  padding: 4em 0;

  text-align: center;
}
.faq .container {
  display: flex;
  flex-direction: column;
}
.faq h3 {
  width: 100%;
}

.faq .faq-item {
  background: linear-gradient(to top left, #000 80%, rgba(132, 83, 237, 0.7));
  max-width: 500px;
  padding: 1em;
  margin: 1em 0;

  border: 1px solid rgba(255, 255, 255, 0.41);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

footer {
  background: none;
  color: #fff;
  text-align: center;
  padding: 1em 2em;
}

footer .footer-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1em;
}

footer .footer-nav a {
  color: #fff;
  text-decoration: none;
}

/* Add the following styles to your existing CSS file */

.contact-form {
  color: #fff;
  background: none;
  padding: 4em 0;
  text-align: center;
}
.contact-form .container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 1em;
  max-width: 400px;
  margin: 0 auto;
}

.contact-form label {
  font-size: 1em;
  text-align: left;
}

.contact-form input {
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
}

.contact-form button {
  background: linear-gradient(90deg, #3f51b5, transparent) #2196f3;
  color: #fff;
  padding: 0.75em 1.5em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: rgba(132, 83, 237, 1);
  color: #fff;
  transition: all 200ms ease-in;
  transform: scale(1.1);
}
input {
  font-family: "Poppins", sans-serif;
  font-size: 16px; /* Adjust font size as needed */
  color: #000000; /* Optional: change the color of the placeholder text */
}

.testimonial-style {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;

  border-radius: 10px;
  padding: 0;
  margin-top: 2em;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#thankYouMessage {
  font-weight: 700;
  background: none;
  height: 401px;
  margin: 0;
  font-size: 1.2em;
  text-align: center;
  color: #fff;
}
.social-icons {
  padding: 30px;
  color: #fff;
  background-color: none;
  text-align: center;
}
.social-icons a {
  color: #fff;
  line-height: 30px;
  font-size: 30px;
  margin: 0 5px;
  text-decoration: none;
}
.social-icons a i {
  line-height: 30px;
  font-size: 30px;
  -webkit-transition: all 200ms ease-in;
  -webkit-transform: scale(1);
  -ms-transition: all 200ms ease-in;
  -ms-transform: scale(1);
  -moz-transition: all 200ms ease-in;
  -moz-transform: scale(1);
  transition: all 200ms ease-in;
  transform: scale(1);
}
.social-icons a:hover i {
  box-shadow: 0px 0px 150px #000000;
  z-index: 2;
  -webkit-transition: all 200ms ease-in;
  -webkit-transform: scale(1.5);
  -ms-transition: all 200ms ease-in;
  -ms-transform: scale(1.5);
  -moz-transition: all 200ms ease-in;
  -moz-transform: scale(1.5);
  transition: all 200ms ease-in;
  transform: scale(1.5);
}

/* Responsive Styles */
@media (max-width: 1250px) {
  .container {
    margin: 0 auto;
    width: 100%;
    /* padding:; */
  }
  .tools-block {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    flex-wrap: wrap;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
    grid-auto-columns: 1fr;
    justify-content: center;
    display: grid;
  }
  .tools-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  header {
    flex-direction: column;
    align-items: flex-start;
  }
  .contact-btn {
    margin-right: 20px;
    width: 90px;
    text-align: center;
  }
  nav ul {
    display: none;
  }

  .hero h2 {
    font-size: 2em;
  }
  .hero {
    padding-left: 25px;
    padding-right: 10px;
  }

  .services .con {
    flex-direction: column;
    align-items: center;
  }

  .services .service-item {
    flex: 1 1 100%;
    min-width: 250px;
    max-width: 400px;
  }
  .process .container {
    flex-direction: column;
  }

  .about .stats {
    flex-direction: column;
  }

  .about .stat {
    flex: 1 1 100%;
    max-width: 400px;
  }

  footer .footer-nav {
    display: flex;
    margin-left: 27%;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 1em;
  }
  footer p {
    margin-left: 30%;
  }

  .process ol {
    flex-direction: column;
    align-items: center;
  }

  .testimonials blockquote {
    margin-left: 30%;
  }
  .faq .faq-item {
    margin: 1em 1em;
  }
  footer .footer-nav {
    display: flex;
    margin-left: 31%;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 1em;
  }
  footer p {
    margin-left: 32%;
  }
}

@media (max-width: 480px) {
  .hero h2 {
    font-size: 1.5em;
  }
  .hero {
    padding: 4em 0;
    padding-left: 2em;
    padding-right: 2em;
  }
  nav ul {
    display: none;
  }

  .hero p {
    margin: 5px;
    padding: 20px;
    padding-top: 10px;
    font-size: 1em;
  }
  .hero h2 {
    margin: 0;
  }

  .services .con {
    flex-direction: column;
    align-items: center;
  }
  .services .service-item {
    flex: 1 1 100%;
    min-width: 250px;
    max-width: 350px;
  }
  .services {
    padding-top: 0.1em;
  }
  .about .stats {
    display: flex;
    flex-wrap: wrap;

    align-items: center;
    margin-top: 2em;
  }

  .about .stat {
    background: linear-gradient(to top left, #000 80%, rgba(132, 83, 237, 0.7));
    padding: 1em;
    border: 1px solid rgba(255, 255, 255, 0.41);
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    flex: 1 1 calc(25% - 1em);
    margin: 0.5em;
    box-sizing: border-box;
  }
  .about p {
    padding-left: 25px;
    padding-right: 25px;
  }
  .contact-btn {
    margin-right: 20px;
    width: 89px;
    text-align: center;
  }
  .testimonials blockquote {
    margin-left: 13%;
  }
  footer {
    position: relative;
    bottom: 25px;
  }
  footer .footer-nav {
    display: flex;
    margin-left: 15%;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 1em;
  }
  footer p {
    margin-left: 17%;
  }
  .tools-block {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    flex-wrap: wrap;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
    grid-auto-columns: 1fr;
    justify-content: center;
    align-items: center;
    display: grid;
  }
  .tools-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #thankYouMessage img {
    width: 350px;
  }
}
